import { render, staticRenderFns } from "./basicDetailPage.vue?vue&type=template&id=3a2f9ea5&scoped=true"
import script from "./basicDetailPage.vue?vue&type=script&lang=js"
export * from "./basicDetailPage.vue?vue&type=script&lang=js"
import style0 from "./basicDetailPage.vue?vue&type=style&index=0&id=3a2f9ea5&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2f9ea5",
  null
  
)

export default component.exports