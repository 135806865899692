<template>
    <div>
        <img style="width:100%" :src="`${$route.meta.imageUrl}?imageView2/interlace/1`" alt="" />
    </div>
</template>
<script>
import mobileBanner from "./homeComponents/mobileBanner.vue";
export default {
    components: {
        mobileBanner,
    },
    data() {
        return {
        };
    },
    methods: {
        jump(index) {
            if (index !== this.imageData.length - 1) return;
            location.href =
                "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7159463927893723405&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=&spread=7Q6WWWR&storefront_id_list=";
        },
    },
};
</script>
<style scoped lang="less">
.content {
    width: 100%;
    max-width: 750px;
    margin: 0px auto;
}
</style>